'use strict';

var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./product/quickView'));
    // Spark changes for image swap and wishlist - BEGIN
    processInclude(require('./product/spark/imageSwap'));
    processInclude(require('./product/wishlistHeart'));
    // Spark changes for image swap and wishlist - END
});
