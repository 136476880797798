'use strict';

var mobile = /android|webos|iphone|ipad|ipod|blackberry|playbook|bb\d+|opera mini/i.test(navigator.userAgent);

module.exports = {
    swapImage: function () {
        // Spark changes for image swap : BEGIN
        var swap = function (e) {
            var mainImage = $(this).attr('data-first-img');
            var secondImage = $(this).attr('data-second-img');
            if (secondImage && e.type === 'mouseenter') {
                mainImage = secondImage;
            }
            $(this).attr('src', mainImage);
        };

        $(document).ready(function () {
            if (!mobile) {
                $('body').on({
                    mouseenter: swap,
                    mouseleave: swap
                }, '.product-grid .product-tile .image-container img');
            }
        });
        // Spark changes for image swap : BEGIN
    },
    swapSwatch: function () {
        // Spark changes for image swap : BEGIN
        $(document).ready(function () {
            if (!mobile) {
                var swatch = function (e) {
                    var imgDiv = $(this).closest('.product-tile').find('.image-container img');
                    var mainImage = imgDiv.attr('data-first-img');
                    var swatchImage = $(this).attr('data-swatch-img');
                    if (swatchImage && e.type === 'mouseenter') {
                        mainImage = swatchImage;
                    }
                    imgDiv.attr('src', mainImage);
                };
                $('body').on({
                    mouseenter: swatch,
                    mouseleave: swatch
                }, '.product-grid .product-tile .color-swatches img');
            }
        });
        // Spark changes for image swap : END
    }
};
