'use strict';
var base = require('./base');
var baseQuickView = require('base/product/quickView');

delete baseQuickView.getModalHtmlElement;

/**
 * New function in Spark that replaced getModalHtmlElement
 * renders the quick view modal
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function renderModal(selectedValueUrl) {
    if ($('#quickViewModal').length !== 0) {
        $('#quickViewModal').remove();
    }

    $.spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            $('body').append(data.renderedTemplate);
            var $container = $('#quickViewModal');
            $container.find('.full-pdp-link').attr('href', data.productUrl);
            $container.find('.size-chart').attr('href', data.productUrl);
            $container.modal('show');

            $('body').on('click', '.pdp-carousel .carousel-item a', function (e) {
                e.preventDefault();
            });

            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 * @param {Object} gtmJsonData - gtm json data to set against div
 */
function fillModalElement(selectedValueUrl, gtmJsonData) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('.modal-body').empty();
            $('.modal-body').html(parsedHtml.body);
            $('.modal-footer').html(parsedHtml.footer);
            $('.full-pdp-link').text(data.quickViewFullDetailMsg);
            $('#quickViewModal .full-pdp-link').attr('href', data.productUrl);
            $('#quickViewModal .size-chart').attr('href', data.productUrl);
            $('#quickViewModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#quickViewModal .enter-message').text(data.enterDialogMessage);
            // Spark changes for GTM - GA4: BEGIN
            if (gtmJsonData) {
                $('#quickViewModal .modal-content .product-quickview').attr('data-gtmjsondata', JSON.stringify(gtmJsonData));
            }
            // Spark changes for GTM - GA4: END
            $('#quickViewModal').modal('show');
            $('body').trigger('quickview:ready');
            $('#quickViewModal').trigger('quickview:afterShow');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

baseQuickView.showQuickview = function () {
    $('body').on('click', '.quickview', function (e) {
        e.preventDefault();
        var selectedValueUrl = $(this).closest('a.quickview').attr('href');
        // Spark changes for GTM - GA4: BEGIN
        var gtmJsonData;
        var $closestDataElement = $(this).closest('[data-gtmjsondata]');
		if ($closestDataElement.length > 0) {
			gtmJsonData = JSON.parse($closestDataElement[0].dataset.gtmjsondata);
		}
        renderModal(selectedValueUrl, e.target);
        fillModalElement(selectedValueUrl, gtmJsonData);
        // Spark changes for GTM - GA4: END
    });
};

/**
 * New function for Spark
 */
baseQuickView.selectBonusProduct = function () {
    if ($('#chooseBonusProductModal').length) {
        base.selectBonusProduct();
    }
};

/**
 * New function for Spark
 */
baseQuickView.addBonusProductsToCart = function () {
    if ($('#chooseBonusProductModal').length) {
        base.addBonusProductsToCart();
    }
};

baseQuickView.productCommon = function () {
    var desktopZoomImageElement = $('.desktop-custom-zoom .desktop-image-container');
    if (desktopZoomImageElement.length) {
        $('body').on('click', '.desktop-custom-zoom .custom-zoom', function (e) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
        });
    }
};

baseQuickView.focusOutOfStockProduct = function () {
    var outOfStockElement = $('.outofstock-product');
    var partialOutOfStockElement = $('.partial-outofstock-product');
    var scrollPosition = $('.header-main').height() + 30;
    var scrollPos;
        if (outOfStockElement.length > 0) {
            scrollPos = outOfStockElement.offset().top - scrollPosition;
            $(window).on('load', function () {
                $('html, body').animate({ scrollTop: scrollPos }, 500);
            });
        } else if (partialOutOfStockElement.length > 0) {
                scrollPos = partialOutOfStockElement.offset().top - scrollPosition;
                $(window).on('load', function () {
                    $('html, body').animate({ scrollTop: scrollPos }, 500);
                });
        }
};

module.exports = baseQuickView;
